"use client"
import { useRouter } from "next/navigation"

import LoginLayout from "../LoginLayout"
import GoogleLogo from "@/assets/icons/google-logo.svg"
import EmailLogin from "./EmailLogin"
import { BoltaEvent } from "@/utils/mixpanels"
import Tooltip from "@/components/Tooltip/Tooltip"

export default function Page() {
  const lastAuthorizedMethod = window.localStorage.getItem("lastAuthorizedMethod")
  const router = useRouter()

  const handleGoogleLogin = () => {
    window.location.href = `${process.env.NEXT_PUBLIC_API_URL}/bolta/oauth2/authorization/google`
  }

  const redirectToEmailJoin = () => {
    router.push("/join/email-join")
  }

  return (
    <LoginLayout>
      <div className="mb-7 text-[28px] font-bold">로그인</div>
      <Tooltip
        text="최근 로그인"
        placement="top"
        offset={-5}
        showArrow
        isOpen={lastAuthorizedMethod === "google"}
      >
        <a
          className="mb-6 flex cursor-pointer items-center justify-center rounded-md border border-[#E9EBEE] py-3 text-secondary"
          onClick={() => {
            BoltaEvent.track("clicked__google_login")
            handleGoogleLogin()
          }}
        >
          <GoogleLogo className="mr-2" />
          Google로 시작하기
        </a>
      </Tooltip>
      <EmailLogin />
      <div className="mt-3 flex items-center justify-end text-[15px] font-medium">
        <a
          className="text-quaternary cursor-pointer"
          onClick={() => router.push("/find-password")}
        >
          비밀번호 찾기
        </a>
        <div className="mx-3 text-[#D9D9D9]">|</div>
        <a
          className="cursor-pointer text-primary"
          onClick={() => {
            BoltaEvent.track("clicked__sign_up")
            redirectToEmailJoin()
          }}
        >
          회원가입
        </a>
      </div>
    </LoginLayout>
  )
}
