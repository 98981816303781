"use client"
import BoltaFullLogo from "@/assets/icons/bolta-full-logo.svg"

type Props = {
  children: React.ReactNode
}

export default function LoginLayout({ children }: Props) {
  return (
    <div className="min-h-[100dvh] bg-[#EBF0F5] md:p-[60px]">
      <div className="flex min-h-[100dvh] flex-col bg-white p-8 md:min-h-[calc(100dvh-120px)] md:rounded-[24px] md:p-10">
        <BoltaFullLogo />
        <div className="flex h-[100%] grow items-center justify-center">
          <div className="w-full max-w-[480px]">{children}</div>
        </div>
      </div>
    </div>
  )
}
