import { create } from "zustand"
import { persist } from "zustand/middleware"
import { Profile, WorkspaceInfo } from "@/types/profile"

interface ProfileState {
  profile: Profile | null
  workspaces: WorkspaceInfo[]
  setProfile: (profile: Profile | null) => void
  setWorkspaces: (workspaces: WorkspaceInfo[]) => void
  getAuthorizedFeatures: () => string[]
}

export const useProfileStore = create<ProfileState>()(
  persist(
    (set, get) => ({
      profile: null,
      workspaces: [],
      setProfile: profile => set({ profile }),
      setWorkspaces: workspaces => set({ workspaces: workspaces }),
      getAuthorizedFeatures: () => {
        return get().profile?.collaborator?.authorizedFeatures || []
      },
    }),
    {
      name: "profile", // persist key
    },
  ),
)
