import { useState, useCallback } from "react"
import { useForm, FormProvider } from "react-hook-form"
import { rules } from "./validations"
import { signIn } from "@/app/api/account/api"
import { LoginForm } from "./types"
import { trimValues } from "@/utils/trimValues"
import debounce from "lodash/debounce"

import TextField from "@/components/TextField/TextField"
import LoginIcon1 from "@/assets/icons/login-icon-1.svg"
import LoginIcon2 from "@/assets/icons/login-icon-2.svg"
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline"
import { Button } from "@nextui-org/react"
import { BoltaEvent } from "@/utils/mixpanels"

export default function EmailLogin() {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false)
  const [isCapsLock, setIsCapsLock] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const methods = useForm<LoginForm>({
    defaultValues: {
      email: "",
      password: "",
    },
  })

  const handleLogin = async (loginForm: LoginForm) => {
    if (isLoading) return
    setIsLoading(true)
    loginForm = trimValues(loginForm)

    BoltaEvent.track("clicked__email_login")
    try {
      await signIn(loginForm.email, loginForm.password, null)
    } finally {
      setIsLoading(false)
    }
  }

  const debouncedSubmit = useCallback(
    debounce((loginForm: LoginForm) => {
      handleLogin(loginForm)
    }, 350),
    [isLoading],
  )

  const onSubmit = async (loginForm: LoginForm) => {
    debouncedSubmit(loginForm)
  }

  return (
    <>
      <FormProvider {...methods}>
        <TextField
          name="email"
          className="bolta-input"
          placeholder="업무용 이메일"
          rules={rules.email}
          nextFocus="password"
          startContent={<LoginIcon1 className="shrink-0" />}
        />
        <TextField
          name="password"
          type={isPasswordVisible ? "" : "password"}
          placeholder="비밀번호"
          className="bolta-input mb-1 mt-3"
          rules={rules.password}
          startContent={<LoginIcon2 className="shrink-0" />}
          endContent={
            <a
              className="cursor-pointer"
              onClick={() => setIsPasswordVisible(!isPasswordVisible)}
            >
              {isPasswordVisible ? (
                <EyeIcon className="w-6 text-[#CED1D7]" />
              ) : (
                <EyeSlashIcon className="w-6 text-[#CED1D7]" />
              )}
            </a>
          }
          onKeyUp={e => setIsCapsLock(e.getModifierState("CapsLock"))}
          onKeyDown={e => {
            if (e.key === "Enter") {
              methods.handleSubmit(onSubmit)()
            }
          }}
        />

        {isCapsLock && (
          <div className="mb-4 text-[13px] text-primary">
            Caps Lock이 켜져 있습니다.
          </div>
        )}
      </FormProvider>

      <Button
        size="lg"
        color="primary"
        className="mt-3 w-full !rounded-md"
        onClick={methods.handleSubmit(onSubmit)}
      >
        로그인
      </Button>
    </>
  )
}
