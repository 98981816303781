export function trimValues(obj) {
  if (obj === null || typeof obj !== "object") {
    // 객체가 아니거나 null인 경우, 변경 없이 반환
    return obj
  }

  // 배열의 경우 각 요소에 대해 재귀적으로 처리
  if (Array.isArray(obj)) {
    return obj.map(trimValues)
  }

  // 객체의 경우, 각 키-값 쌍에 대해 재귀적으로 처리
  return Object.keys(obj).reduce((acc, key) => {
    let value = obj[key]

    if (typeof value === "string") {
      value = value.trim()
      // 빈 문자열이면 null로 변환
      acc[key] = value === "" ? null : value
    } else {
      acc[key] = trimValues(value)
    }

    return acc
  }, {})
}
