import { useSnackbarStore } from "@/store/snackbarStore"
import { TooltipProps } from "@nextui-org/react"

import { Tooltip as _Tooltip } from "@nextui-org/react"
import AlertCircleIcon from "@/assets/icons/alert-circle.svg"

interface Props extends TooltipProps {
  text: string
  maxWidth?: number
  offset?: number
  children?: React.ReactNode
}

export default function Tooltip({
  text,
  maxWidth,
  offset,
  children,
  ...props
}: Props) {
  const openSnackbar = useSnackbarStore(state => state.openSnackbar)

  return (
    <>
      <_Tooltip
        {...props}
        content={text}
        color="foreground"
        placement={props.placement || "top"}
        offset={offset}
        classNames={{
          content: `rounded-small px-[10px] py-[6px] break-keep whitespace-pre-wrap ${
            maxWidth ? `max-w-[${maxWidth}px]` : ""
          }`,
          base: "-top-1",
        }}
      >
        {children ? (
          children
        ) : (
          <div>
            <AlertCircleIcon
              className={`mb-[8px] ml-1 hidden md:block ${props.className}`}
            />
          </div>
        )}
      </_Tooltip>
      {children ? null : (
        <div>
          <AlertCircleIcon
            className={`mb-[8px] ml-1 max-sm:cursor-pointer md:hidden ${props.className}`}
            onClick={() => openSnackbar(text, "info")}
          />
        </div>
      )}
    </>
  )
}
